import { useContextSafely } from 'hooks/useContextSafely';
import React, {
  type ReactElement,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { ToastProps } from '@fragment/ui/src/components/Toast/Toast';

type ToastManagerContextType = {
  showToast: (toast: ReactElement) => void;
  dismissToast: (toast: ReactElement) => void;
};

export const ToastManagerContext =
  createContext<ToastManagerContextType | null>(null);

const DEFAULT_TOAST_DURATION_MS = 6000;

export const useToastManager = () => {
  const timers = useRef<Map<ReactElement, NodeJS.Timeout>>(new Map());
  const [toastStack, setToastStack] = useState<ReactElement[]>([]);
  const dismissToast = (toast: ReactElement) => {
    setToastStack((prev) => prev.filter((t) => t !== toast));
    clearTimeout(timers.current.get(toast));
    timers.current.delete(toast);
  };

  useEffect(
    () => () => {
      timers.current.forEach((timer) => {
        clearTimeout(timer);
      });
      timers.current.clear();
    },
    []
  );

  return useMemo(
    () => ({
      toastStack,
      showToast: (
        toast: ReactElement,
        duration = DEFAULT_TOAST_DURATION_MS
      ) => {
        setToastStack((prev) => [...prev, toast]);
        const timer = setTimeout(() => {
          if (!toast.props.persistent) {
            dismissToast(toast);
          }
        }, duration);
        timers.current.set(toast, timer);
      },
      dismissToast,
    }),
    [toastStack]
  );
};

export const useToast = () => useContextSafely(ToastManagerContext);

type Props = {
  toastStack: ReactElement<ToastProps>[];
};

export const ToastRoot = ({ toastStack }: Props) => (
  <div className="absolute flex flex-col bottom-f2 right-f2 z-50 text-md">
    {toastStack.map((toast) => toast)}
  </div>
);
