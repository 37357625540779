import clsx from 'clsx';
import React from 'react';
import { Listbox } from '@headlessui/react';

import type {
  TWBackgroundProp,
  TWBorderColorProp,
  TWBorderWidthProp,
  TWPositionProp,
} from '../../types/tailwind';
import { Icon } from '../Icon';
import { type TextSizeType, getTextSize, strictClassNames } from '../utils';
import { StaticIconType, SvgIconType } from '@fragment/ui/src/components/Icon/BaseIcon/Icon';

export type Option = {
  value: string;
  label: React.ReactNode;
};

type Props<BackgroundProp, BorderWidthProp, PositionProp, BorderColorProp> = {
  placeholder?: React.ReactNode;
  displayValue: string;
  onChange: (value: string) => void;
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
  options: Option[];
  label?: string;
  disabled?: boolean;
  size?: TextSizeType;
  hasError?: boolean;
  border?: boolean;
  borderWidth?: TWBorderWidthProp<BorderWidthProp>;
  backgroundColor?: TWBackgroundProp<BackgroundProp>;
  borderColor?: TWBorderColorProp<BorderColorProp>;
  hoverBackgroundColor?: TWBackgroundProp<BackgroundProp>;
  padding?: string;
  height?: string;
  width?: string;
  buttonHeight?: string;
  'data-testid'?: string;
  extraClassNames?: string;
  dropDownHeightClassName?: string;
  position?: TWPositionProp<PositionProp>;
  icon?: StaticIconType | SvgIconType;
};
export const Dropdown = <
  BackgroundProp,
  BorderWidthProp,
  PositionProp,
  BorderColorProp
>({
  options,
  onChange,
  onContextMenu,
  displayValue,
  label,
  disabled = false,
  size = 'sm',
  hasError = false,
  borderWidth = 'border' as TWBorderWidthProp<BorderWidthProp>,
  border = false,
  backgroundColor,
  borderColor = 'border-canvas' as TWBorderColorProp<BorderColorProp>,
  buttonHeight,
  hoverBackgroundColor = 'bg-negative' as TWBackgroundProp<BackgroundProp>,
  placeholder,
  width: propWidth,
  padding,
  height: heightProp,
  'data-testid': dataTestId,
  extraClassNames,
  dropDownHeightClassName,
  position = 'relative' as TWPositionProp<PositionProp>,
  icon,
}: Props<
  BackgroundProp,
  BorderWidthProp,
  PositionProp,
  BorderColorProp
>): JSX.Element => {
  const lookupDisplayName = (val: string) =>
    options.find((option) => option.value === val)?.label;
  const displayName = lookupDisplayName(displayValue);

  const height = heightProp ?? 'h-f2';
  const textSize = getTextSize(size);
  const width = propWidth ?? 'w-full';
  return (
    <Listbox value={displayValue} onChange={onChange}>
      {({ open }) => (
        <div
          className={clsx(
            `space-y-f1 pl-[1/2ch] ${position} ${width}`,
            extraClassNames
          )}
          data-testid={dataTestId}
        >
          {label && (
            <Listbox.Label>
              <span className="text-main-500">{label}</span>
            </Listbox.Label>
          )}
          <div className={position}>
            <Listbox.Button
              aria-disabled={disabled}
              data-testid="dropdown-button"
              className={clsx(
                'group-dropdown',
                disabled
                  ? 'hover:cursor-not-allowed'
                  : `hover:cursor-pointer hover:${hoverBackgroundColor}`,
                backgroundColor ?? 'bg-transparent',
                // Set a border width regardless of the border prop/open state
                borderWidth,
                // No border if border prop is false and dropdown is closed
                !border && ['border-none', 'focus:border-none'],
                // Border if dropdown is open
                open && !hasError && borderColor,
                // Border if border prop is true
                border && !hasError && [borderColor, `focus:${borderColor}`],
                // Border if hasError and border prop is true
                border && hasError && 'border-orange',
                width,
                padding,
                buttonHeight ?? height,
                textSize,
                ['focus-visible:outline-main']
              )}
            >
              <div
                className="flex items-center justify-between"
                onContextMenu={(e) => {
                  if (!open && onContextMenu) {
                    onContextMenu(e);
                  }
                }}
              >
                <div className="truncate">
                  <span
                    className={strictClassNames(
                      !disabled && displayName
                        ? ['text-main', 'group-dropdown-hover:text-main']
                        : ['text-main-500']
                    )}
                  >
                    {lookupDisplayName(displayValue) ||
                      (placeholder ?? 'Select Option')}
                  </span>
                </div>
                <div className="group-dropdown-hover:text-main text-main-500">
                  <Icon type={icon ?? 'select'} size={size} />
                </div>
              </div>
            </Listbox.Button>

            <Listbox.Options
              data-testid="dropdown-options"
              className={clsx([
                'absolute z-20',
                'border border-b-1 border-l-0 border-r-0',
                'scrollbar-hide',
                borderColor,
                'bg-main-200',
                'hover:cursor-pointer',
                dropDownHeightClassName ?? 'max-h-f14',
                'overflow-y-auto',
                width,
                'focus-visible:outline-main',
              ])}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  value={option.value}
                  className={clsx('focus-visible:outline-transparent')}
                >
                  <div
                    data-testid="dropdown-option"
                    className={clsx(
                      'px-1em',
                      'flex items-center',
                      'hover:bg-negative',
                      'border-b-[1px] border-canvas',
                      backgroundColor ?? 'bg-main-200',
                      height,
                      padding,
                      textSize,
                      option.value === displayValue && 'text-main-500'
                    )}
                  >
                    <div className="flex items-center space-x-f1 w-full">
                      <div className="truncate">
                        <span>{option.label}</span>
                      </div>
                    </div>
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </div>
      )}
    </Listbox>
  );
};
