import React from 'react';

import {
  ToastManagerContext,
  ToastRoot,
  useToastManager,
} from '../components/ToastManager';

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const { toastStack, showToast, dismissToast } = useToastManager();
  const toastContext = React.useMemo(
    () => ({ toastStack, showToast, dismissToast }),
    [toastStack, showToast, dismissToast]
  );
  return (
    <ToastManagerContext.Provider value={toastContext}>
      {children}
      <ToastRoot toastStack={toastStack} />
    </ToastManagerContext.Provider>
  );
};
