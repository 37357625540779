import { memoize } from 'lodash-unified';
import { z } from 'zod';

export const UNSAFE_CHARACTERS = ['#', '/', ':'] as const;

export const isUnsafeString = (value: string): value is UnsafeString => {
  return !!UNSAFE_CHARACTERS.find((character) => {
    return value.includes(character);
  });
};

const unsafePathCharacters = ['#'] as const;

export const isSafePathString = (value: string) => {
  return (
    value !== '' &&
    !unsafePathCharacters.find((character) => value.includes(character)) &&
    !value.startsWith('/') &&
    !value.endsWith('/') &&
    !value.includes('//')
  );
};

export const SafePathStringSchema = z
  .string()
  .superRefine((value, ctx) => {
    if (value === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Path cannot be an empty string. Received ${value}`,
      });
    }
    if (value.startsWith('/') || value.endsWith('/')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Path cannot start or end with a forward slash. Received ${value}`,
      });
    }
    if (value.includes('//')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Path cannot contain consecutive forward slashes. Received ${value}`,
      });
    }
    if (unsafePathCharacters.find((character) => value.includes(character))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Path cannot contain the characters: ${unsafePathCharacters.join(
          ','
        )}. Received ${value}`,
      });
    }
  })
  .brand<'SafePathString'>();

export type SafePathString = z.TypeOf<typeof SafePathStringSchema>;

const emailRegex = /^[^\s@+]+@[^\s@\/]+\.[^\s@\/]+$/;
export const EmailAddressSchema = z
  .string()
  .superRefine((value, ctx) => {
    if (!emailRegex.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid email address. Cannot include whitespace, +, more than one @, or @ at the end. Received ${value}`,
      });
    }
  })
  .brand<'EmailAddress'>();

export type EmailAddress = z.TypeOf<typeof EmailAddressSchema>;

const paramRegex = /{{([^{}]*?)}}/g;
export const getStringParameters = memoize((s: string) => {
  return [...s.matchAll(paramRegex)]
    .map((match) => match[1].trim())
    .filter((param) => {
      // '__proto__' is an unsafe parameter and an attempt at prototype pollution
      return param !== '__proto__';
    });
});

export const getStringParametersInternal = (s: string, params: Set<string>) => {
  getStringParameters(s).forEach((param) => {
    params.add(param);
  });
};

export type UnsafeString =
  | `${string}#${string}`
  | `${string}:${string}`
  | `${string}/${string}`;

export const SafeStringSchema = z
  .string()
  .refine((s) => !isUnsafeString(s) && getStringParameters(s).length === 0, {
    message: `String cannot contain '#', '/', ':', or parameters in {{handlebar}} syntax`,
  })
  .brand<'SafeString'>();

export const DateTimeSchema = z.string().datetime().brand<'DateTime'>();
export type DateTime = z.TypeOf<typeof DateTimeSchema>;

export type SafeString = z.TypeOf<typeof SafeStringSchema>;

export const ParameterizedString = z
  .string()
  .transform((s) => {
    // Strip whitespace within brackets & vars, e.g. { {  a var   }  } => {{a var}}
    return (
      s
        // Strip between two left brackets
        .replace(/\{(\s*)(.*?)\s*\{/g, '{$2{')
        // Two right brackets
        .replace(/\}(\s*)(.*?)\s*\}/g, '}$2}')
        // Well-formed double-brackets
        .replace(/\{\{(\s*)(.*?)\s*\}\}/g, '{{$2}}')
    );
  })
  .refine(
    (s) => {
      // Verify brackets are balanced
      return /^[^{}]*({{[^{}]*}}[^{}]*)*$/m.test(s);
    },
    {
      message: `Parameterized string must have exactly two left brackets followed by exactly two right brackets.`,
    }
  )
  .refine(
    (s) => {
      const regex = /{{([^{}]*?)}}/g; // Regular expression to match {{word}} {{word2}}
      const matches = [...s.trim().matchAll(regex)];
      return matches.every(
        ([_, param]) =>
          param !== '' && !param.includes(' ') && param[0].match(/[a-zA-Z]/)
      );
    },
    {
      message: `Parameters (e.g. {{param}}) must be a non-empty string beginning with an alphabetical character and may not include whitespace.`,
    }
  );

export const ParameterizedAmount = ({
  optional,
  errorMessage,
}: {
  optional?: boolean;
  errorMessage?: string;
} = {}) => {
  const schema = optional
    ? ParameterizedString.optional()
    : ParameterizedString;
  return schema.superRefine((s, ctx): void => {
    if (!s) {
      return;
    }
    let remaining = s.trim();
    const valueRegex = /^(-?\d+|\{\{[^}]*\}*\}\})/; // A parameter starts with {{ and ends at the first string of } that is at least 2 long.
    const plusminusRegex = /^[+-]/;
    let expect = 'start' as 'start' | 'plusminus' | 'value';

    while (remaining.length > 0) {
      switch (expect) {
        case 'start': {
          const m1 = remaining.match(valueRegex);
          if (m1 && m1[0].length > 0) {
            expect = 'plusminus';
            remaining = remaining.slice(m1[0].length).trim();
            // eslint-disable-next-line no-continue
            continue;
          }
          const m2 = remaining.match(plusminusRegex);
          if (m2 && m2[0].length > 0) {
            expect = 'value';
            remaining = remaining.slice(m2[0].length).trim();
            // eslint-disable-next-line no-continue
            continue;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              errorMessage ??
              `Invalid expression at character "${remaining[0]}". Expressions must start with +, -, a number or {{param}}`,
          });
          return;
        }
        case 'plusminus': {
          const m1 = remaining.match(plusminusRegex);
          if (m1 && m1[0].length > 0) {
            expect = 'value';
            remaining = remaining.slice(m1[0].length).trim();
            // eslint-disable-next-line no-continue
            continue;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              errorMessage ??
              `Invalid expression at character "${remaining[0]}". Expected + or - at this point.`,
          });
          return;
        }
        case 'value': {
          const m1 = remaining.match(valueRegex);
          if (m1 && m1[0].length > 0) {
            expect = 'plusminus';
            remaining = remaining.slice(m1[0].length).trim();
            // eslint-disable-next-line no-continue
            continue;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              errorMessage ??
              `Invalid expression at character "${remaining[0]}". Expected a number or {{param}}`,
          });
          return;
        }
        default: {
          return;
        }
      }
    }
    if (expect !== 'plusminus') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          errorMessage ?? 'Invalid expression. Unexpected end of string.',
      });
    }
  });
};

export const safe = (splits: TemplateStringsArray, ...values: string[]) =>
  SafeStringSchema.parse(
    splits.reduce((acc, split, i) => acc + split + (values[i] || ''), '')
  );

export const safePath = (splits: TemplateStringsArray, ...values: string[]) =>
  SafePathStringSchema.parse(
    splits.reduce((acc, split, i) => acc + split + (values[i] || ''), '')
  );

/**
 * Validates the name given to an AWS Step Function execution.
 * @see https://docs.aws.amazon.com/step-functions/latest/apireference/API_StartExecution.html#API_StartExecution_RequestSyntax
 * */
export const SfnExecutionNameSchema = SafeStringSchema.superRefine(
  (val, ctx) => {
    if (val.length < 1 || val.length > 80) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Name must be between 1 and 80 characters long. Received: ${val.length}.`,
      });
      return;
    }
    if (val.startsWith('_') || val.endsWith('_')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Name cannot start or end with an underscore.`,
      });
      return;
    }
    if (!/^[a-zA-Z0-9_-]+$/.test(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Name can only contain alphanumeric characters, hyphens, and underscores.`,
      });
    }
  }
).brand<'SfnExecutionName'>();

export type SfnExecutionName = z.TypeOf<typeof SfnExecutionNameSchema>;

/** Defines the maximum length permitted for free-form text fields in the API. */
export const MAX_FREEFORM_TEXT_LENGTH = 1000;

/** Defines the maximum length of custom currency IDs and their names */
// 36 is the length of a UUID. 32 characters + 4 dashes. We expect customers to use those as their custom currency IDs.
export const MAX_CUSTOM_CURRENCY_ID_LENGTH = 36;

/** Defines the maximum length of custom currency custom codes */
export const MAX_CUSTOM_CURRENCY_CODE_LENGTH = 5;

/** Defines the maximum length of a workspace name */
export const MAX_WORKSPACE_NAME_LENGTH = 128;
