import clsx from 'clsx';
import { useToast } from 'components/ToastManager';
import {
  useCreateUserWorkspaceInviteMutation as useCreateUserWorkspaceInviteMutationGlobal,
  useGetPendingUserWorkspaceInvitesQuery,
  useGetWorkspaceMembersQuery as useGetWorkspaceMembersQueryGlobal,
} from 'hooks/globalApi';
import { useGlobalApiContext } from 'hooks/useGlobalApiContext';
import { useUserInfo } from 'hooks/useUserInfo';
import { useWorkspaceId } from 'hooks/useWorkspace';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getResultOrError } from 'utils/errors';
import { useCatchMutationError } from 'utils/urql';

import lilId from '@fragment/lil-id';
import { EmailAddressSchema } from '@fragment/types/strings';
import { FormButtons } from '@fragment/ui/src/components/Button/ButtonGroup/FormButtons';
import { TertiaryButton } from '@fragment/ui/src/components/Button/TertiaryButton/TertiaryButton';
import { Icon } from '@fragment/ui/src/components/Icon/BaseIcon/Icon';
import { TextInput } from '@fragment/ui/src/components/Input/TextInput';
import { Toast } from '@fragment/ui/src/components/Toast/Toast';

const validateEmail = (email: string) => {
  const lowerCaseEmail = email.toLowerCase();
  return EmailAddressSchema.safeParse(lowerCaseEmail).success;
};

type InviteMemberProps = {
  setShowAddMemberView: (show: boolean) => void;
};

const isEmailInOrganization = (email: string, orgDomain: string) => {
  if (!email.includes('@') || !orgDomain) return false;
  const domain = email.split('@')[1];
  return domain === orgDomain;
};

export const InviteMember = ({ setShowAddMemberView }: InviteMemberProps) => {
  const kMaxInvites = 20;

  const workspaceId = useWorkspaceId();
  const { context } = useGlobalApiContext();
  const { showToast } = useToast();
  const [emailField, setEmailField] = useState<string>('');
  const [hasValidEmails, setHasValidEmails] = useState<boolean>(false);
  const showWarningsWhileTyping = useRef(false);
  // get pending invites and existing members
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data, error: workspaceError }] = useGetWorkspaceMembersQueryGlobal({
    variables,
    context,
  });
  const { result: workspace } = getResultOrError(
    data?.workspace,
    workspaceError
  );
  const { result: members } = getResultOrError(
    workspace?.members,
    workspaceError
  );

  const [{ data: pendingMembersData, error: pendingMembersError }] =
    useGetPendingUserWorkspaceInvitesQuery({
      variables: { workspaceId },
      context,
    });

  const { result: pendingInvites } = getResultOrError(
    pendingMembersData?.workspace,
    pendingMembersError
  );

  const existingMemberEmails = useMemo(() => {
    if (members?.nodes) {
      return new Set(
        members.nodes.flatMap((member) => {
          if (member?.user.email) {
            return member.user.email;
          }
          return [];
        })
      );
    }
    return new Set();
  }, [members]);

  const pendingMemberEmails = useMemo(() => {
    if (pendingInvites?.pendingInvites) {
      return new Set(
        pendingInvites.pendingInvites.flatMap((invite) => {
          if (!invite.email || !invite.inviteId) {
            return [];
          }
          return invite.email;
        })
      );
    }
    return new Set();
  }, [pendingInvites]);

  // determine the domain of the inviter's email
  const { data: userData } = useUserInfo();
  const orgDomain = userData?.email?.split('@')[1] ?? '';

  const [newMemberEmails, setNewMemberEmails] = useState<Set<string>>(
    new Set()
  );

  // show an error message if the email is invalid or already in the list
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (errorMessage) {
      timer = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [errorMessage]);

  // create a new user workspace invite mutation and callback
  const [{ fetching: isInviting, error }, createUserWorkspaceInvite] =
    useCatchMutationError(
      useCreateUserWorkspaceInviteMutationGlobal,
      'createUserWorkspaceInvite'
    );

  // email validation
  const validateNewEmail = useCallback(
    (email: string, showWarning = true) => {
      const lowerCaseEmail = email.toLowerCase();
      if (!validateEmail(lowerCaseEmail)) {
        if (showWarning) setErrorMessage('Invalid email address');
        return false;
      }
      if (newMemberEmails.has(lowerCaseEmail)) {
        if (showWarning) setErrorMessage('Email already in list');
        return false;
      }
      if (existingMemberEmails.has(lowerCaseEmail)) {
        if (showWarning) setErrorMessage('User already added to workspace');
        return false;
      }
      if (pendingMemberEmails.has(lowerCaseEmail)) {
        if (showWarning) setErrorMessage('User already invited to workspace');
        return false;
      }
      if (newMemberEmails.size >= kMaxInvites) {
        if (showWarning)
          setErrorMessage(
            `You can only invite up to ${kMaxInvites} members at a time`
          );
        return false;
      }
      setErrorMessage(null);
      return true;
    },
    [existingMemberEmails, pendingMemberEmails, newMemberEmails]
  );

  // key input handler
  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      let isValid = validateNewEmail(
        emailField,
        showWarningsWhileTyping.current
      );
      setHasValidEmails(isValid);
      if (e.code === 'Enter' || e.code === 'Comma' || e.code === 'Space') {
        showWarningsWhileTyping.current = true;
        isValid = validateNewEmail(emailField, true);
        setNewMemberEmails((prev) => {
          const newSet = new Set(prev);
          if (isValid) {
            newSet.add(emailField.toLowerCase());
            setEmailField('');
            showWarningsWhileTyping.current = false;
          }
          return newSet;
        });
      }
    },
    [emailField, validateNewEmail]
  );

  // handles pasting in of multiple emails
  const onPasteHandler = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');
      // allows any combo of separation by space and commas
      // split by space, trim, filter empty strings, join into single string, split by commas
      const emails = text
        .split(' ')
        .map((email) => email.trim())
        .filter((email) => email.length > 0)
        .join(',')
        .split(',')
        .filter((email) => email.length > 0);

      // Only process emails up to the 20 member limit
      const remainingSlots = kMaxInvites - newMemberEmails.size;
      const emailsToProcess = emails.slice(0, remainingSlots);

      const validEmails = emailsToProcess.filter((email) =>
        validateNewEmail(email.toLowerCase(), false)
      );
      setEmailField('');

      if (emails.length > remainingSlots) {
        setErrorMessage(
          `You can only invite up to ${kMaxInvites} members at a time`
        );
      }

      setNewMemberEmails((prev) => {
        const newSet = new Set(prev);
        validEmails.forEach((email) => {
          newSet.add(email.toLowerCase());
        });
        return newSet;
      });
      if (validEmails.length > 0) {
        setHasValidEmails(true);
      }
      if (validEmails.length < emailsToProcess.length)
        setErrorMessage(
          `${
            emailsToProcess.length - validEmails.length
          } invalid email(s) provided, only adding valid emails`
        );
    },
    [validateNewEmail, newMemberEmails]
  );

  const inviteUsers = useCallback(async () => {
    const invites = Array.from(newMemberEmails).map((email) => ({
      email,
      inviteId: lilId(),
    }));
    if (emailField.length > 0) {
      if (validateNewEmail(emailField)) {
        invites.push({
          email: emailField,
          inviteId: lilId(),
        });
      } else {
        return;
      }
    }
    const result = await createUserWorkspaceInvite(
      {
        workspaceId,
        invites,
      },
      context
    );
    if (result.error) {
      if (result.error.message.includes('No invites provided')) {
        showToast(<Toast type="error" message="No invites provided" />);
      } else {
        showToast(
          <Toast
            type="error"
            message="Failed to invite members, please ensure all email addresses are valid and try again."
          />
        );
      }
    } else {
      setShowAddMemberView(false);
      showToast(<Toast type="success" message="Invited members" />);
    }
  }, [
    createUserWorkspaceInvite,
    showToast,
    setShowAddMemberView,
    emailField,
    newMemberEmails,
    validateNewEmail,
    workspaceId,
    context,
  ]);

  return (
    <div className="flex flex-col w-full h-full justify-between items-start">
      <div className="flex flex-col w-full h-full  items-start">
        <div className="text-main-500">Invite members</div>
        {Array.from(newMemberEmails).map((email, i) => (
          <div
            key={email}
            className={clsx(
              'flex flex-row flex-wrap text-main w-full border-main-300 border-[0.5px] ',
              i > 0 && 'border-t-0'
            )}
          >
            {email}
            <TertiaryButton
              data-testid="remove-email-button"
              onClick={() => {
                setNewMemberEmails((prev) => {
                  const newSet = new Set(prev);
                  newSet.delete(email);
                  return newSet;
                });
              }}
            >
              <Icon type="close" size="md" />
            </TertiaryButton>
            {!isEmailInOrganization(email, orgDomain) && (
              <div className="text-main-500 flex flex-row pl-f1 items-center">
                <Icon
                  type="alert"
                  size="md"
                  backgroundColor="gray"
                  primaryColor="white"
                />
                email is outside your organization
              </div>
            )}
          </div>
        ))}
        <div
          className={clsx(
            newMemberEmails.size > 0
              ? 'border-main-300 border-[0.5px] border-t-0'
              : 'border-main-300 border-[0.5px]',
            'w-full'
          )}
        >
          <TextInput
            autoFocus
            transparent
            width="w-full"
            align="left"
            flexContainer="space-y-f0"
            size="md"
            padding="p-f0"
            placeholder="Add emails"
            value={emailField}
            onKeyDown={keyDownHandler}
            onPaste={onPasteHandler}
            onChange={(e) => {
              setEmailField(e.target.value);
              if (
                e.target.value.endsWith(',') ||
                e.target.value.endsWith(' ')
              ) {
                setEmailField(e.target.value.slice(0, -1));
              }
            }}
          />
        </div>
        {errorMessage && (
          <div className="text-main flex items-center gap-f0">
            <Icon
              type="alert"
              backgroundColor="orange"
              primaryColor="white"
              size="md"
            />
            {errorMessage}
          </div>
        )}
      </div>

      <FormButtons
        type="tertiary"
        onCancel={() => setShowAddMemberView(false)}
        cancelButtonLabel="Cancel"
        submitButtonLabel={isInviting ? 'Inviting...' : 'Invite'}
        disabled={!hasValidEmails || isInviting}
        onSubmit={inviteUsers}
      />
    </div>
  );
};
