import assert from 'assert';
import { useToast } from 'components/ToastManager';
import {
  useCreateUserWorkspaceInviteMutation,
  useDeleteUserWorkspaceInviteMutation,
  useGetPendingUserWorkspaceInvitesQuery,
  useGetWorkspaceInviteByIdQuery,
  useGetWorkspaceMembersQuery as useGetWorkspaceMembersQueryGlobal,
} from 'hooks/globalApi';
import { useGlobalApiContext } from 'hooks/useGlobalApiContext';
import React, { useCallback, useMemo, useState } from 'react';
import { getResultOrError } from 'utils/errors';

import lilId from '@fragment/lil-id';
import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';
import { TertiaryButton } from '@fragment/ui/src/components/Button/TertiaryButton/TertiaryButton';
import { Dropdown } from '@fragment/ui/src/components/Dropdown/Dropdown';
import { Icon } from '@fragment/ui/src/components/Icon';
import { Table } from '@fragment/ui/src/components/Table';
import { Toast } from '@fragment/ui/src/components/Toast/Toast';

import type { MembersListRow } from '../../../settings/MembersList';
import { formatDate } from '../../../../utils/date';
import { useWorkspaceId } from '../../../../wrappers/WorkspaceProvider';
import { MemberAccessRevoker } from '../../../settings/MemberAccessRevoker';
import { TableLoadError } from '../../../TableLoadError/TableLoadError';
import { InviteMember } from './InviteMember';

const membersSchema = [
  {
    title: 'Members',
    key: 'email',
  },
  {
    title: 'Date Added',
    format: (value: MembersListRow) => <span>{formatDate(value.created)}</span>,
    justify: 'right',
  },
] as const;

const invitesSchema = [
  {
    title: 'Invites',
    colspan: 'col-span-3',
    key: 'email',
  },
  {
    title: 'Created',
    format: (value: MembersListRow) => (
      <div className="absolute right-0 z-3 flex items-center justify-end">
        <Dropdown
          displayValue={formatDate(value.created)}
          placeholder={
            <div className="text-left text-main">
              {formatDate(value.created)}
            </div>
          }
          width="w-full"
          size="md"
          extraClassNames="z-4"
          options={[
            { value: 'resend', label: 'Resend' },
            { value: 'delete', label: 'Delete' },
          ]}
          onChange={(action: string) => {
            if (action === 'resend') {
              if (value.resend) {
                value.resend();
              }
            }
            if (action === 'delete') {
              if (value.delete) {
                value.delete();
              }
            }
          }}
          icon="down"
        />
      </div>
    ),
    justify: 'right',
    colspan: 'col-span-1',
  },
] as const;

const InvitesListTable = ({ workspaceId }: { workspaceId: string }) => {
  const { context } = useGlobalApiContext();
  const { showToast } = useToast();
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data, error, fetching }, reExecuteMembersQuery] =
    useGetPendingUserWorkspaceInvitesQuery({
      variables,
      context,
    });

  const { isError: isWorkspaceError, result: workspace } = getResultOrError(
    data?.workspace,
    error
  );

  const refreshInvitesTable = useCallback(
    () => reExecuteMembersQuery({ requestPolicy: 'network-only' }),
    [reExecuteMembersQuery]
  );

  // call back for deleting a user invite
  const [, deleteUserWorkspaceInvite] = useDeleteUserWorkspaceInviteMutation();
  const deleteInvite = useCallback(
    async (inviteId: string, shouldShowToast = true) => {
      const response = await deleteUserWorkspaceInvite(
        { workspaceId, inviteId },
        context
      );
      if (
        response.data?.deleteUserWorkspaceInvite.__typename ===
        'BadRequestError'
      ) {
        showToast(
          <Toast
            type="error"
            message="An error occurred while deleting the invite. Please try again."
          />
        );
        return false;
      }
      if (shouldShowToast) {
        showToast(
          <Toast type="success" message="Invite deleted" id={inviteId} />
        );
      }
      return true;
    },
    [deleteUserWorkspaceInvite, workspaceId, showToast, context]
  );

  // call back for resending a user invite (delete then create new with same email)
  const [, createUserWorkspaceInvite] = useCreateUserWorkspaceInviteMutation();
  const resendInvite = useCallback(
    async (inviteId: string, email: string) => {
      const newInviteId = lilId();
      const result = await createUserWorkspaceInvite(
        {
          workspaceId,
          invites: [{ inviteId: newInviteId, email }],
        },
        context
      );
      if (
        result.data?.createUserWorkspaceInvite.__typename === 'BadRequestError'
      ) {
        showToast(
          <Toast
            type="error"
            message="An error occurred while resending the invite. Please try again or create a new one."
          />
        );
      } else {
        // delete the original invite if we successfully sent a new one
        const deleted = await deleteInvite(inviteId, false);
        if (deleted) {
          showToast(<Toast type="success" message="Invite resent" />);
        } else {
          // delete the new invite if we failed to delete the original one
          await deleteInvite(newInviteId, false);
          showToast(
            <Toast
              type="error"
              message="An error occurred while resending the invite. Please try again or create a new one."
            />
          );
        }
      }
    },
    [createUserWorkspaceInvite, workspaceId, context, deleteInvite, showToast]
  );

  if (isWorkspaceError) {
    return (
      <TableLoadError
        entityColumnName="Email"
        rightColumnName="Date Added"
        notFoundErrorCode="member_not_found"
        {...{
          error,
          retry: refreshInvitesTable,
          fetching,
        }}
        data-testid="invites-list-table-error"
      />
    );
  }
  const InvitesRowData = workspace.pendingInvites.flatMap((invite) => {
    if (!invite.email || !invite.inviteId) {
      return [];
    }
    return [
      {
        id: invite.inviteId,
        email: invite.email,
        created: invite.created,
        delete: async () => {
          await deleteInvite(invite.inviteId);
        },
        resend: async () => {
          await resendInvite(invite.inviteId, invite.email);
        },
      },
    ];
  });

  return (
    <Table
      textSize="md"
      data={InvitesRowData}
      schema={invitesSchema}
      borderedRows
      emptyValue={{
        Invites: 'No Invites',
        Created: 'N/A',
      }}
      data-testid="invites-list-table"
    />
  );
};

const MembersListTable = ({
  workspaceId,
  onRowClick,
}: {
  workspaceId: string;
  onRowClick: (row: MembersListRow) => void;
}) => {
  const { context } = useGlobalApiContext();
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data, error, fetching }, reExecuteMembersQuery] =
    useGetWorkspaceMembersQueryGlobal({
      variables,
      context,
    });

  const { isError: isWorkspaceError, result: workspace } = getResultOrError(
    data?.workspace,
    error
  );
  const { isError: isMembersError, result: members } = getResultOrError(
    workspace?.members,
    error
  );

  const memberRowData = isMembersError
    ? []
    : members.nodes.flatMap((member) => {
        if (member?.user.__typename !== 'User' || !member.user.email) {
          return [];
        }
        return [
          {
            id: member.user.id,
            email: member.user.email,
            created: member.created,
          },
        ];
      });

  const refreshMemberTable = useCallback(
    () => reExecuteMembersQuery({ requestPolicy: 'network-only' }),
    [reExecuteMembersQuery]
  );

  if (isWorkspaceError || isMembersError) {
    return (
      <TableLoadError
        entityColumnName="Email"
        rightColumnName="Date Added"
        notFoundErrorCode="member_not_found"
        {...{ error, retry: refreshMemberTable, fetching }}
        data-testid="members-list-table-error"
      />
    );
  }

  return (
    <Table
      textSize="md"
      data={memberRowData}
      schema={membersSchema}
      onRowClick={onRowClick}
      borderedRows
      data-testid="members-list-table"
    />
  );
};

export const MembersList = () => {
  const workspaceId = useWorkspaceId();
  const { context, token } = useGlobalApiContext();
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data: workspaceData }] = useGetWorkspaceInviteByIdQuery({
    variables,
    context,
    pause: !token,
  });
  assert(workspaceData?.workspace.__typename === 'Workspace');
  const [clickedMember, setClickedMember] = useState<MembersListRow>();
  const [showAddMemberView, setShowAddMemberView] = useState<boolean>(false);

  return (
    <div className="flex flex-col w-full h-full">
      {clickedMember ? (
        <>
          <div className="flex justify-between">
            <TertiaryButton
              onClick={() => {
                setClickedMember(undefined);
              }}
            >
              <div className="flex space-x-[1ch] items-center">
                <Icon type="left" size="md" />
                <span>Back</span>
              </div>
            </TertiaryButton>
          </div>
          <div className="h-f4" />
          <MemberAccessRevoker
            row={clickedMember}
            onDelete={() => {
              setClickedMember(undefined);
            }}
          />
        </>
      ) : (
        <>
          <div className="flex justify-between pb-f4">
            <span>Members</span>
            {!showAddMemberView && (
              <PrimaryButtonV2
                onClick={() => {
                  setShowAddMemberView(true);
                }}
              >
                Invite+
              </PrimaryButtonV2>
            )}
          </div>
          {showAddMemberView ? (
            <InviteMember setShowAddMemberView={setShowAddMemberView} />
          ) : (
            <div className="flex flex-col gap-f4">
              <InvitesListTable workspaceId={workspaceId} />
              <MembersListTable
                workspaceId={workspaceId}
                onRowClick={setClickedMember}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
