import type { ReactElement } from 'react';
import React from 'react';

import { Button } from '../Button/BaseButton/Button';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { OverflowTooltip } from '../Tooltip/OverflowTooltip';
import lilId from '@fragment/lil-id';
type NoActionProps = {
  onClick?: never;
  text?: never;
};

type ActionProps = {
  onClick: VoidFunction;
  text?: string;
};

type ErrorToastProps = (NoActionProps | ActionProps) & { type: 'error' };

type SuccessToastProps = NoActionProps & { type: 'success' };

export type ToastProps = (SuccessToastProps | ErrorToastProps) & {
  message: ReactElement | string;
  width?: string;
  showIcon?: boolean;
  persistent?: boolean;
  id?: string;
};

export const Toast = ({
  message,
  type,
  onClick,
  text = 'Retry',
  showIcon = true,
  // Used by the ToastManager
  // eslint-disable-next-line unused-imports/no-unused-vars
  persistent = false,
  width = 'w-[450px]',
  id,
}: ToastProps) => (
  <div
    className={`${width} bg-negative flex flex-row`}
    data-testid="toast"
    key={id ?? lilId()}
  >
    {showIcon && (
      <div className="flex h-f2 items-center px-[1px]">
        {type === 'error' ? (
          <Icon
            type="error"
            backgroundColor="orange"
            data-testid="error-icon"
          />
        ) : (
          <Icon
            type="success"
            backgroundColor="black"
            data-testid="success-icon"
          />
        )}
      </div>
    )}
    <div className="grow">
      <OverflowTooltip
        maxW="max-w-full"
        lineClamp="line-clamp-[3]"
        baseComponent={
          <Text data-testid="toast-message" whitespace="whitespace-normal">
            {message}
          </Text>
        }
        overflowComponent={
          <Text
            data-testid="toast-overflow-message"
            as="div"
            maxW="max-w-[900px]"
            whitespace="whitespace-normal"
          >
            {message}
          </Text>
        }
      />
    </div>
    {type === 'error' && onClick && (
      <Button
        cursor="hover:cursor-pointer"
        color="text-main-500 hover:text-main"
        h="h-f2"
        onClick={onClick}
      >
        {text}&nbsp;
        <Icon type="right" data-testid="retry-icon" />
      </Button>
    )}
  </div>
);
