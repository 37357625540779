import React from 'react';

import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';

export const GlobalApiAuthenticationFailed = () => (
  <div className="space-y-f2 text-md w-1/3">
    <span>Failed to authenticate user</span>
    <p>
      This is most likely our fault. If the problem persists, please contact{' '}
      <a
        href="mailto:help@fragment.dev"
        className="hover:cursor-pointer decoration-main-500 hover:decoration-main underline transition-all duration-150 ease-in-out"
      >
        support
      </a>
      .
    </p>
  </div>
);

export const PodApiAuthenticationFailed = () => (
  <div className="space-y-f2 text-md w-1/3">
    <span>Failed to authenticate workspace</span>
    <p>
      This is most likely our fault. Try switching to other workspaces. If the
      problem persists, please contact{' '}
      <a
        href="mailto:help@fragment.dev"
        className="hover:cursor-pointer decoration-main-500 hover:decoration-main underline transition-all duration-150 ease-in-out"
      >
        support
      </a>
      .
    </p>
    <PrimaryButtonV2 onClick={() => window.location.reload()}>
      Refresh
    </PrimaryButtonV2>
  </div>
);
