import type { CombinedError } from 'urql';
import React from 'react';

import { TextButton } from '@fragment/ui/src/components/Button/TextButton/TextButton';
import { Icon } from '@fragment/ui/src/components/Icon';
import { InlineError } from '@fragment/ui/src/components/InlineError/InlineError';
import { LoadingText } from '@fragment/ui/src/components/LoadingText';
import type { ColumnDefinition } from '@fragment/ui/src/components/Table';
import { Table } from '@fragment/ui/src/components/Table';
import { useLoadingText } from '@fragment/ui/src/hooks/LoadingText';

type ErrorTableProp = {
  id: string;
  error: string | React.ReactNode;
  retry?: () => void;
};

const schema = (
  entityColumnName: string,
  rightColumnName: string
): ColumnDefinition<ErrorTableProp>[] => [
  {
    title: entityColumnName,
    key: 'error',
    format: (row) => (
      <div className="flex flex-row text-main items-center">
        <InlineError margin="m-f0">
          <span>{row.error}</span>
        </InlineError>
      </div>
    ),
  },
  {
    title: rightColumnName,
    justify: 'right',
    key: 'retry',
    format: (row) => (
      <div className="flex justify-end">
        {row.retry && (
          <TextButton inverted onClick={row.retry}>
            Retry <Icon type="right" />
          </TextButton>
        )}
      </div>
    ),
  },
];

export type TableLoadErrorProps = {
  entityColumnName: string;
  rightColumnName?: string;
  error?: CombinedError;
  notFoundErrorCode?: string;
  entityId?: string;
  retry: () => void;
  fetching: boolean;
  'data-testid'?: string;
};

export const TableLoadError = ({
  entityColumnName,
  rightColumnName = '',
  error,
  notFoundErrorCode,
  entityId,
  retry,
  fetching,
  'data-testid': dataTestId = 'table-load-error',
}: TableLoadErrorProps): JSX.Element => {
  const loadingText = useLoadingText('Loading');

  if (
    error?.graphQLErrors.some(
      (err) =>
        err.extensions.code === notFoundErrorCode || err.message === 'Not found'
    )
  ) {
    return (
      <div className="flex flex-col">
        <span className="text-main-500">{entityColumnName}</span>
        {fetching ? (
          <span className="mt-f2">{loadingText}</span>
        ) : (
          <span>{`"${entityId}" Not Found`}</span>
        )}
      </div>
    );
  }

  return (
    <Table
      textSize="md"
      data={[
        {
          id: '1',
          error: fetching ? <LoadingText text="Retrying" /> : 'Loading Error',
          retry,
        },
      ]}
      schema={schema(entityColumnName, rightColumnName)}
      data-testid={dataTestId}
      borderedRows
    />
  );
};
